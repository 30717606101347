<template>
  <div>
    <v-container>
      <Uploadcsv />      
    </v-container>
  </div>
</template>

<script>
export default {
  components: {
    Uploadcsv: () => import("@/components/uploadcsv"),
  },
};
</script>